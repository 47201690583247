.buy {
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: radial-gradient(
            34.24% 34.24% at 50% 50%,
            var(--color-ellipsis-shadow-1) 0%,
            rgba(var(--color-ellipsis-shadow-2-rgb), 0.53) 33.33%,
            rgba(var(--color-ellipsis-shadow-3-rgb), 0) 100%
        );
        mix-blend-mode: normal;
        opacity: 0.14;
        top: 0;
        left: 0;
        position: absolute;
        z-index: -1;
    }

    .container {
        @include rwdmax(desktop) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .h2 {
        @include rwdmax(desktop) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}
