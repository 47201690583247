.language-switcher {
    cursor: pointer;
    color: var(--color-text-medium);
    font-size: rem(14px);
    display: flex;
    align-items: center;
    transition: $link-transition;
    white-space: nowrap;
    direction: ltr;
    line-height: 1;

    @include rwdmax(tablet) {
        position: fixed;
        left: 0;
        z-index: 1;
        bottom: 0;
        padding: 1rem;
        border-top: 1px solid rgba(var(--color-text-medium-rgb), 0.3);
        width: 100%;
    }

    &::before {
        content: '';
        background-color: var(--color-text-medium);
        mask: url('../../icons/globe.svg?inline') no-repeat 50% 50%;
        mask-size: cover;
        width: rem(14px);
        height: rem(14px);
        min-width: rem(14px);
        min-height: rem(14px);
        transition: $link-transition;
        margin-right: rem(4px);
    }

    &:hover {
        color: var(--color-text-dark);

        &::before {
            background-color: var(--color-text-dark);
        }
    }
}
