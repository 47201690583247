.box {
    background: var(--color-text-light);
    box-shadow: 0 0 1px rgba(142, 142, 142, 25%),
    0 84px 289px rgba(211, 211, 211, 18%),
    0 19px 65px rgba(211, 211, 211, 12%), 0 6px 19px rgba(211, 211, 211, 8%);

    border-radius: 20px;

    img {
        width: 100%;
        max-width: fit-content;
        padding-top: 1rem;
        object-fit: contain;
    }

    &--center {
        text-align: center;
        box-shadow: 60px 40px 204px -27px rgba(var(--color-shadow-rgb), 0.39),
        0 19px 45px rgba(var(--color-shadow-rgb), 0.08),
        0 6px 13px rgba(var(--color-shadow-rgb), 0.04);

        @include rwdmax(desktop) {
            box-shadow: 0 0 103px rgba(234, 215, 255, 18%), 0 0 1px #e9d5ff,
            4px 8px 126px 3px rgba(234, 215, 255, 71%);
        }
    }

    .buy & {
        box-shadow: 0 0 0;
    }

    &__content {
        padding: rem(24px) rem(16px);
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid var(--color-light-primary);
        padding: 1.5rem rem(48px);
        text-align: left;

        .rtl & {
            text-align: right;
        }

        @include rwdmax(desktop) {
            flex-direction: column;
            padding: rem(24px) 1rem;
            align-items: start;
        }

        .product-name {
            color: var(--color-ellipsis-shadow-1);

            @extend %paragraph--big;

            margin-bottom: 0.5rem;
            display: block;
        }

        a {
            @include rwdmax(desktop) {
                margin-top: 1rem;
                width: 100%;
            }
        }
    }
}

.homepage-box {
    &--container {
        @include rwd(tablet) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .btn {
        font-size: rem(14px);
    }

    .product-name {
        @include rwd(desktop) {
            font-size: rem(20px);
            line-height: 1.2;
        }
    }

    .h4 {
        @include rwd(desktop) {
            font-size: rem(24px);
            line-height: 1.2;
        }
    }

    img {
        padding: 2rem 2rem 1rem;
        width: 100%;
        object-fit: contain;
        object-position: center;

        @include rwdmax(tablet) {
            height: auto;
        }

        @include rwd(tablet) {
            max-width: fit-content;
            height: rem(350px);
        }
    }
}

.box {
    &-model {
        font-weight: 500;
        font-size: rem(14px);
        line-height: 135%;
        color: $color-text-medium;
    }

    &-name {
        font-weight: 500;
        font-size: rem(20px);
        line-height: 145%;
        margin: 8px 0;
    }

    &-label {
        background: $color-bg-primary;
        border-radius: 32px;
        font-style: normal;
        font-weight: 400;
        font-size: rem(14px);
        line-height: 145%;
        color: var(--color-text-medium-darker);
        padding: 3px 10px;
        margin: 2px;

        &-wrapper {
            margin-top: 8px;
        }
    }
}

.box__highlight {
    padding: $spacer-half 0;
    font-size: rem(14px);
    white-space: break-spaces;
}
