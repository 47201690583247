$directions: ('row', 'column');

@each $direction in $directions {
    .u-flex-#{$direction} {
        display: flex;
        flex-direction: #{$direction};
    }
}

$alignments: (
    'center',
    'baseline',
    'space-between',
    'space-around',
    'flex-start',
    'flex-end'
);

@each $align in $alignments {
    .u-justify-content-#{$align} {
        justify-content: #{$align};
    }

    .u-justify-items-#{$align} {
        justify-items: #{$align};
    }

    .u-justify-self-#{$align} {
        justify-self: #{$align};
    }

    .u-align-content-#{$align} {
        align-content: #{$align};
    }

    .u-align-items-#{$align} {
        align-items: #{$align};
    }

    .u-align-self-#{$align} {
        align-self: #{$align};
    }
}

.u-flex {
    &-column {
        width: 100%;
    }

    &-tablet-column {
        @include rwdmax(desktop) {
            flex-direction: column;
            width: 100%;
        }
    }

    &-mobile-column {
        @include rwdmax(tablet) {
            flex-direction: column;
            width: 100%;
        }
    }
}

.u-full {
    @include rwdmax(desktop) {
        width: 100%;
    }
}

.u-flex-wrap {
    flex-wrap: wrap;
}

.u-flex-flow {
    flex-flow: wrap;
}
