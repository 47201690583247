/* stylelint-disable selector-class-pattern */

.btn {
    appearance: none;
    box-sizing: border-box;
    border-radius: rem(90px);
    font-weight: $font-weight-medium;
    color: var(--color-text-base);
    transition: all 0.3s;
    font-size: rem(16px);
    padding: rem(10px) rem(40px);
    line-height: rem(24px);
    cursor: pointer;
    outline: 0;
    border-width: 1px;
    border-style: solid;

    &--primary {
        color: var(--color-button-primary-text);
        background: var(--color-button-primary-light);
        border-color: var(--color-button-primary-light);

        &:hover {
            background: var(--color-button-primary-light-hover);
            border-color: var(--color-button-primary-light-hover);
        }

        &:disabled {
            opacity: 0.65;
            cursor: default;
        }

        &.btn--dark {
            color: var(--color-button-primary-text-dark);
            background: var(--color-button-primary-dark);
            border-color: var(--color-button-primary-dark);

            &:hover {
                background: var(--color-button-primary-dark-hover);
                border-color: var(--color-button-primary-dark-hover);
            }
        }
    }

    &--secondary {
        color: var(--color-button-secondary-text);
        background: var(--color-button-secondary-dark);
        border-color: var(--color-button-secondary-dark);

        &:hover {
            background: var(--color-button-secondary-dark-hover);
            border-color: var(--color-button-secondary-dark-hover);
        }

        &:disabled {
            opacity: 0.65;
            cursor: default;
        }
    }

    &--tertiary {
        color: var(--color-button-tertiary-text);
        background-color: var(--color-text-dark);
        border-color: var(--color-text-dark);

        &:hover {
            background: var(--color-button-tertiary-hover);
            border-color: var(--color-button-tertiary-hover);
        }
    }

    &--outline {
        background-color: transparent;
        color: var(--color-text-dark);
        border-color: var(--color-text-dark);

        &:hover {
            background-color: var(--color-text-dark);
            color: var(--color-light-primary);
        }
    }

    &--full {
        width: 100%;
        white-space: nowrap;
    }

    &--full-mobile {
        @include rwdmax(tablet) {
            width: 100%;
            white-space: nowrap;
        }
    }

    &--small {
        padding: rem(7px) rem(24px);
    }

    &--extra-small {
        padding: rem(4px) rem(20px);
        font-size: $font-small;
    }

    &--icon {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 0.75rem;
            height: 0.75rem;
            margin-right: 0.5rem;
        }
    }

    &--no-wrap {
        white-space: nowrap;
    }
}
