/*
h1
h2
h3
 */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: rem($font-pixels-base);
    margin: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-top: 0;
    margin-bottom: 0;
}

.h1 {
    // Gladiora
    font-family: var(--gladoria-font), sans-serif;
    font-weight: $font-weight-gladiora-bold;
    font-size: rem(36px);
    line-height: 1.24;

    @include rwd(desktop) {
        font-size: rem(72px);
    }
}

.h2 {
    // Gladiora
    font-family: var(--gladoria-font), sans-serif;
    font-weight: $font-weight-gladiora-semi-bold;
    font-size: rem(32px);
    line-height: 1.18;

    @include rwd(desktop) {
        font-size: rem(48px);
        line-height: 1.24;
    }
}

.h3 {
    // Neue Frutiger
    font-weight: $font-weight-medium;
    font-size: rem(28px);
    line-height: 1.26;

    @include rwd(desktop) {
        font-size: rem(36px);
        line-height: 1.35;
    }

    &--small {
        @include rwd(desktop) {
            font-size: 1.25rem !important;
        }
    }
}

.h4 {
    // Neue Frutiger
    font-weight: $font-weight-medium;
    font-size: rem(24px);
    line-height: 1.3;

    @include rwd(desktop) {
        font-size: rem(28px);
        line-height: 1.4;
    }
}

.h5 {
}

.h6 {
}
