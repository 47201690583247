$text-alignments: ('left', 'right', 'center');

@each $text-align in $text-alignments {
    .u-text-#{$text-align} {
        text-align: #{$text-align};
    }
}

.u-desktop-text-center {
    @include rwd(desktop) {
        text-align: center;
    }

    .highlight-text {
        margin-top: 2rem;

        @include rwd(desktop) {
            margin-top: 8px;
        }
    }
}

.u-text-opacity-25 {
    opacity: 0.25;
}
