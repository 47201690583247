/* stylelint-disable selector-class-pattern */

.highlight-text {
    color: var(--color-text-medium);

    span {
        color: var(--color-text-dark);
    }

    &--blend {
        mix-blend-mode: screen;
    }

    &--block {
        span {
            display: inline-block;
        }
    }

    &.u-padding-top-medium {
        padding-top: $spacer !important;
    }

    .section--dark &,
    .section--black & {
        span {
            color: var(--color-bg-primary);
        }
    }

    .section--black & {
        color: var(--color-text-medium-lighter);
    }
}
