.menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    grid-column: 4/9;
    align-self: end;
    height: 100%;
    width: 100%;

    @include rwdmax(tablet) {
        display: none;
    }

    a {
        display: inline-flex;
        position: relative;
        text-decoration: none;
        cursor: pointer;
        color: var(--color-text-medium);
        transition: 0.3s all;
        height: 100%;
        align-items: center;

        &::before {
            content: '';
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: transparent;
        }

        &:hover {
            color: var(--color-text-dark);
        }

        &:active,
        &.active {
            outline: 0;
            color: var(--color-text-dark);

            &::before {
                background: var(--color-text-dark);
            }
        }
    }
}
