.products {
    &-counter {
        font-weight: 400;
        font-size: rem(16px);
        line-height: 145%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: $color-text-medium;
    }
}

.product {
    box-shadow: 0 0 1px rgba(142, 142, 142, 25%),
        0 84px 289px rgba(211, 211, 211, 18%),
        0 19px 65px rgba(211, 211, 211, 12%), 0 6px 19px rgba(211, 211, 211, 8%);
    border-radius: 20px;
    transition: ease 0.5s;
    height: 100%;
    padding-bottom: 24px;

    a {
        display: flex;
        flex-direction: column;
        height: calc( 100% - 3rem );;
    }

    &:hover {
        box-shadow: 0 0 1px rgba(142, 142, 142, 25%),
            0 84px 289px rgba(211, 211, 211, 75%),
            0 19px 65px rgba(211, 211, 211, 12%),
            0 6px 19px rgba(211, 211, 211, 8%);
        cursor: pointer;
    }

    .box-meta--container {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    &-image--wrapper {
        border-bottom: 1px solid $color-bg-primary;
        padding: 24px 24px 8px;
    }

    &-meta--wrapper {
        padding: 16px 24px 0;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &-meta-box--wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -2px;
    }

    &-compare {
        color: $color-text-medium;
        text-align: center;
        margin-top: 1em;

        .checkbox-container {
            font-size: $font-small;
            width: auto;
        }
    }
}
