.people {
    align-items: center;
    overflow: hidden;
    padding: 0;

    .highlight-text {
        color: var(--color-light-primary);

        span {
            color: var(--color-dark-primary);
        }
    }

    .circle-shadow {
        @include rwdmax(tablet) {
            display: none;
        }
    }

    & > div {
        position: relative;
        z-index: 1;

        &:not(.people-image) {
            @include rwdmax(desktop) {
                padding: rem(40px) 1rem;
            }
        }
    }

    &-image {
        background-image: url('../../img/people.webp');
        background-position: center;
        width: 100%;
        height: 100%;
        aspect-ratio: 1/1;

        @include rwd(desktop) {
            background-size: contain;
        }

        background-size: cover;

        &--2 {
            background-image: url('../../img/people4.webp');
            background-size: cover;
        }
    }
}
