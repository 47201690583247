.breadcrumbs {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;

    a,
    span {
        color: var(--color-text-medium);
    }

    a:hover {
        color: var(--color-text-dark);
    }

    & > li:not(:first-child)::before {
        content: url('../../icons/arrow-breadcrumbs.svg?inline');
        margin-right: 0.25rem;
        display: inline-block;

        .rtl & {
            transform: rotate(180deg);
        }
    }
}
