.header {
    background: var(--color-bg-body);
    filter: drop-shadow(0 12px 10px rgba(var(--color-header-shadow-rgb), 0.04));
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;

    &-right {
        grid-column: 9/-1;

        @include rwdmax(tablet) {
            display: none !important;
        }
    }

    .grid-container.grid {
        @include rwdmax(tablet) {
            grid-template-columns: repeat(8, 1fr);
        }

        @include rwdmax(desktop) {
            grid-template-columns: repeat(12, 1fr);
        }
    }

    .product-page &:not(.header--active) {
        @include rwdmax(tablet) {
            position: static;
        }
    }
}
