/* stylelint-disable */
.hako {
    .header {
        top: var(--hako-bar-height);
        z-index: 99999;
    }

    .languages-wrapper {
        z-index: 99999;
    }

    &-html {
        h2,
        h3,
        h4 {
            font-size: rem(20);
            line-height: rem(28);
            font-weight: $font-weight-medium;
            margin-top: rem(36px);
            margin-bottom: rem(8px);
        }

        p {
            font-size: rem(16);
            line-height: rem(24);
        }

        strong {
            font-weight: $font-weight-medium;
        }

        ul,ol {
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            padding-inline-start: 40px;
        }

        li {
            margin-top: rem(8px);
            margin-bottom: rem(8px);
        }

    }
    [contenteditable] {
        outline: none;
    }

    .rx-content {
        padding: 0!important;
    }
    .rx-container {
        border: none!important;
    }
}
//
//.hako {
//    &-box {
//        a {
//            font-size: rem(14px);
//            padding: rem(10px) 0;
//            width: fit-content;
//            display: inline;
//            position: relative;
//            text-decoration: none;
//            z-index: 0;
//            color: inherit;
//            background-repeat: no-repeat;
//            background-size: 100% 1px;
//            background-position: 0 75%;
//            transition: background-image 0.2s cubic-bezier(0.96, 0.01, 0.58, 1);
//            background-image: linear-gradient(
//                            rgba(var(--color-text-medium-rgb), 0.75) 0%,
//                            rgba(var(--color-text-medium-rgb), 0.75) 100%
//            );
//            cursor: pointer;
//
//            &:hover,
//            &:active {
//                outline: 0;
//                background-image: linear-gradient(
//                                var(--color-text-dark) 0%,
//                                var(--color-text-dark) 100%
//                );
//            }
//        }
//    }
//}
//
//.field-overlay__overlay,
//.bar {
//    z-index: 999999999;
//}
