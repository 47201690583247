$directions: '', '-top', '-bottom', '-right', '-left';
$sizes: (
    'xsmall': $spacer-one-third,
    'small': $spacer-half,
    'medium': $spacer,
    'large': $spacer-double,
    'xlarge': $spacer-triple,
    'xxlarge': $spacer-quadruple,
    'none': 0,
);

@each $direction in $directions {
    @each $sizeName, $size in $sizes {
        .u-margin#{$direction}-#{$sizeName} {
            margin#{$direction}: $size;

            @include rwdmax(tablet) {
                margin#{$direction}: ($size * 0.5) !important;
            }
        }

        .u-padding#{$direction}-#{$sizeName} {
            padding#{$direction}: $size;

            @include rwdmax(tablet) {
                padding#{$direction}: ($size * 0.5) !important;
            }
        }
    }
}
