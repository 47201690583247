/* stylelint-disable */

#hako {
    @include rwdmax(small) {
        display: none;
    }
}

.hatch {
    &__no-offers {
        @include rwdmax(desktop) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}
