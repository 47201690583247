.logo-philips {
  svg {
    fill: var(--color-text-medium)
  }
}

.footer {
  row-gap: 40px;
}

img {
  display: initial;
}

p,
.paragraph,
%paragraph {
  margin-top: 1em;
  margin-bottom: 1em;
}

.btn {
  display: inline-block;
  text-align: center;
}

.homepage-box, .hero {
  img {
    display: initial;
  }
}

.footer-right {
  justify-content: space-between;
}

.breadcrumbs {
  margin: 16px;
}

.header {
  height: rem(80px);
}

.logo {
  padding: 0;
  align-self: center;
}
.box__footer {
  gap: rem(16px);
}
.product.product--unpublished {
  opacity: .5;
}
