.table {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    column-gap: 5rem;

    @include rwd(tablet) {
        column-count: 2;
    }

    & > div {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        border-bottom: 1px solid rgba(var(--color-text-light-rgb), 0.17);
        width: 100%;
        color: var(--color-text-light);
        gap: 0.5rem;

        &:last-child {
            border-bottom: 0;
        }

        div:last-child {
            color: var(--color-dark-primary);
            break-inside: avoid;
        }

        // stylelint-disable selector-max-universal
        * {
            width: 50%;
        }
    }

    &__group {
        padding-top: 1.5rem;
    }

    &__btn {
        &--hidden {
            display: none;
        }
    }
}
