.checkbox-container {
  width: 100%;
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: rem(22px);
  font-size: rem(16px);
  user-select: none;
  user-select: none;
  user-select: none;
  font-weight: 400;
  line-height: 145%;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &--disabled {
    opacity: .3;
  }
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: $color-bg-primary;

  &::after {
    content: '';
    position: absolute;
    display: none;
  }
}

.checkbox-container {
  & input:checked ~ .checkmark::after {
    display: block;
  }

  .checkmark {
    &::after {
      left: 5px;
      top: 1px;
      width: rem(5px);
      height: rem(10px);
      border: solid $color-text-dark;
      border-width: 0 2px 2px 0;
      border-radius: 1.5px;
      transform: rotate(45deg) translate(50%);
    }
  }
}
