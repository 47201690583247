.logo {
    padding-top: 2rem;
    padding-bottom: 2rem;
    grid-column: 1/4;

    @include rwdmax(tablet) {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    svg {
        max-height: rem(30px);
        max-width: rem(177px);
    }
}
