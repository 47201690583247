.copyright {
    color: var(--color-text-dark);
    font-size: rem(14px);
    padding: 1rem 0;

    &::before {
        content: '';
        width: 100%;
        height: 1px;
        background-color: var(--color-divide);
        position: absolute;
        left: 0;
        transform: translateY(-1rem);
    }
}
