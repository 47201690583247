.u-image {
    &-full {
        width: 100%;
        margin-bottom: -5px;
    }

    &-glow {
        display: block;
        z-index: 0;
        text-align: center;

        &::before {
            @include rwd(tablet) {
                content: '';
                background: radial-gradient(
                    42.55% 42.55% at 50% 50%,
                    var(--color-ellipsis-shadow-1) 0%,
                    rgba(var(--color-ellipsis-shadow-2-rgb), 0.53) 15%,
                    rgba(var(--color-ellipsis-shadow-3-rgb), 0) 100%
                );
                mix-blend-mode: normal;
                opacity: 0.83;
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: -1;
                left: 0;
                bottom: 0;
            }
        }
    }

    &-cutted {
        img {
            @include rwd(tablet) {
                width: rem(825px);
                height: rem(825px);
                object-fit: contain;
            }

            @include rwdmax(tablet) {
                padding-bottom: 3rem;
            }
        }
    }

    &-bg {
        background-image: url('../../img/monitor-bg.webp');
        background-size: cover;
        background-position: center;
        height: 43vw;
        align-items: flex-end;

        @include rwd(desktop) {
            padding-bottom: 2rem;
        }

        .grid-container {
            @include rwd(desktop) {
                margin-top: 7%;
            }
        }

        img {
            display: block;
            max-width: 80%;
            margin: 0 auto;
            max-height: 30vw;
            object-fit: contain;
            object-position: bottom;

            @include rwdmax(desktop) {
                max-width: 60%;
            }
        }
    }
}
