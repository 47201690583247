.footer {
    background: var(--color-bg-primary);
    font-size: rem(14px);
    position: relative;
    overflow: hidden;

    .grid-container {
        &:first-child {
            padding-left: rem(47px) 0;
            grid-column-gap: $spacer-xsmall;
            row-gap: 0;
            column-gap: $spacer-xsmall;
        }
    }

    .logo {
        width: 100%;
        padding-top: 0;

        svg {
            max-height: rem(27px);
            max-width: rem(165px);
        }
    }

    &-menu {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;

        .u-flex-column {
            width: 100%;
        }

        a {
            font-size: rem(14px);
            padding: rem(10px) 0;
            width: fit-content;
            display: inline;
            position: relative;
            text-decoration: none;
            z-index: 0;
            color: inherit;
            background-repeat: no-repeat;
            background-size: 0 0;
            background-position: 0 75%;
            transition: background-size 0.2s cubic-bezier(0.96, 0.01, 0.58, 1);
            background-image: linear-gradient(
                var(--color-text-dark) 0%,
                var(--color-text-dark) 100%
            );
            cursor: pointer;

            &:hover,
            &:active {
                outline: 0;
                background-size: 100% 1px;
            }
        }
    }

    &-right {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include rwd(tablet) {
            align-items: end;
        }

        .logo-philips {
            width: 7rem;

            @include rwdmax(tablet) {
                align-self: end;
                display: flex;
            }
        }
    }
}
