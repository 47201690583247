%column-gap {
    @include rwd(phone) {
        &.column-gap-none {
            column-gap: 0;
        }

        &.column-gap-xsmall {
            column-gap: $spacer-one-fourth;
        }

        &.column-gap-small {
            column-gap: $spacer-half;
        }

        &.column-gap-medium {
            column-gap: $spacer;
        }

        &.column-gap-large {
            column-gap: $spacer-double;
        }

        &.column-gap-xlarge {
            column-gap: $spacer-triple;
        }

        &.column-gap-xxlarge {
            column-gap: $spacer-quadruple;
        }
    }
}

.column-gap-none {
    column-gap: 0;
}

.column-gap-xsmall {
    column-gap: $spacer-one-fourth;
}

.column-gap-small {
    column-gap: $spacer-half;
}

.column-gap-medium {
    column-gap: $spacer;
}

.column-gap-large {
    column-gap: $spacer-double;
}

.column-gap-xlarge {
    column-gap: $spacer-triple;
}

.column-gap-xxlarge {
    column-gap: $spacer-quadruple;
}

.row-gap-none {
    row-gap: 0;
}

.row-gap-xsmall {
    row-gap: $spacer-one-fourth;
}

.row-gap-small {
    row-gap: $spacer-half !important;
}

.row-gap-medium {
    row-gap: $spacer;
}

.row-gap-large {
    row-gap: $spacer-double;
}

.row-gap-xlarge {
    row-gap: $spacer-triple;
}

.row-gap-xxlarge {
    row-gap: $spacer-quadruple;
}
