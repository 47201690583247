/* stylelint-disable selector-class-pattern */

@mixin get-hero-background($url) {
    background: url($url),
        linear-gradient(
            0deg,
            rgba(var(--color-circle-shadow-2-rgb), 0.3),
            rgba(var(--color-circle-shadow-2-rgb), 0.3)
        );
    background-size: cover;
    background-position: center;
}

.hero {
    @include get-hero-background('../../img/hero-bg.webp');

    text-align: center;
    color: var(--color-text-light);
    z-index: 1;

    .theme-blue & {
        @include get-hero-background('../../img/hero-bg-blue.webp');
    }

    .theme-green & {
        @include get-hero-background('../../img/hero-bg-green.webp');
    }

    .u-text-highlight {
        color: var(--color-text-dark);
    }

    &__image-wrapper {
        position: relative;
    }

    &__image {
        width: 48vw;
        max-width: rem(780px);

        @include rwdmax(tablet) {
            width: 64vw;
        }

        @include rwdmax(small-phone) {
            width: 80vw;
        }
    }

    &__slogan {
        width: 100%;
        position: absolute;
        color: var(--color-light-primary);
        text-shadow: 0 4px 100px rgba(134, 74, 202, 75%),
            4px 8px 60px var(--color-shadow-2);
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        font-family: var(--gladoria-font), sans-serif;

        font-weight: $font-weight-gladiora-bold;
        font-size: 3.5rem;

        @include rwdmax(tablet) {
            font-size: rem(40px);
            top: 0;
            position: relative;
        }

        @include rwdmax(phone) {
            margin-top: rem(60px);
        }

        @include rwd(desktop) {
            font-size: 5rem;
            line-height: 1;
        }
    }

    .h4 {
        text-shadow: 0 4px 100px rgba(134, 74, 202, 75%),
            4px 8px 60px var(--color-shadow-2);
    }
}
