@mixin box-shadow {
  box-shadow: 0 0 1px rgba(142, 142, 142, 25%),
  0 84px 289px rgba(211, 211, 211, 18%),
  0 19px 65px rgba(211, 211, 211, 12%), 0 6px 19px rgba(211, 211, 211, 8%);
  border-radius: 20px;
}

.sorting {
  font-weight: 400;
  font-size: rem(16px);
  line-height: 145%;
  color: $color-text-medium;

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      position: relative;
      margin: 0;

      button {
        font-size: inherit;
        border: none;
        background-color: transparent;
        cursor: pointer;
        width: 100%;
      }

      .padding-for-hover {
        padding-top: 12px;
      }

      ul {
        position: absolute;
        right: 0;
        min-width: 100%;
        background: #fff;
        padding: 14px 0;
        display: none;
        z-index: 3;

        @include box-shadow;

        li {
          align-items: center;
          cursor: pointer;
          display: flex;
          transition: 0.3s ease-in-out;
          white-space: nowrap;
          justify-content: space-between;
          padding: 6px 12px;

          &:hover {
            background: $color-bg-primary;
          }

          &.checked span.checked {
            visibility: visible;
          }

          span.checked {
            visibility: hidden;
            align-items: center;
            display: flex;
            white-space: nowrap;
            justify-content: space-between;

            &::after {
              content: '';
              background-color: $color-text-dark;
              mask: url('../../icons/check.svg?inline')
              no-repeat 50% 50%;
              mask-size: cover;
              width: rem(14px);
              height: rem(14px);
              min-width: rem(14px);
              min-height: rem(14px);
              margin-right: rem(4px);
            }
          }

        }
      }
    }
  }

  button {
    color: $color-text-medium;
    display: flex;
    gap: 4px;
    align-items: center;
  }

  &:hover {
    nav ul li ul {
      display: block;
    }
  }

  span {
    color: $color-text-dark;

    &::after {
      content: '';
      background-color: $color-text-medium;
      mask: url('../../icons/chevron-down.svg?inline') no-repeat 50%
      50%;
      mask-size: cover;
      width: rem(9px);
      height: rem(5px);
      min-width: rem(9px);
      min-height: rem(5px);
      margin-right: rem(4px);
    }
  }
}
