.link {
    color: var(--color-link);
    text-decoration: none;
    transition: $link-transition;

    &:visited {
        color: var(--color-link);
    }

    &:hover,
    &:focus,
    &:active {
        color: var(--color-text-base);
    }

    &:focus {
        outline: thin dotted;
    }

    &:hover,
    &:active {
        outline: 0;
    }
}
