.hamburger {
    display: none;

    @include rwdmax(tablet) {
        display: block;
    }

    position: relative;
    z-index: 1;
    user-select: none;
    grid-column: -2/-1;
    align-self: center;
    justify-self: end;

    span {
        display: block;
        width: rem(24px);
        height: 3px;
        margin-bottom: 4px;
        position: relative;
        background: var(--color-text-medium);
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    }

    span:first-child {
        transform-origin: 0 0;
    }

    span:nth-last-child(2) {
        transform-origin: 0 100%;
    }

    &.hamburger--active span {
        opacity: 1;
        transform: rotate(45deg) translate(-7px, -9px);
        background: var(--color-text-medium);
    }

    &.hamburger--active span:nth-last-child(3) {
        opacity: 1;
        transform: rotate(-45deg) translate(-12px, 12px);
    }

    &.hamburger--active span:nth-last-child(2) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }
}
