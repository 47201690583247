.circle-shadow {
    background: radial-gradient(
            circle at 10% -10%,
            transparent 50%,
            var(--color-circle-shadow-1) 0
        ),
        radial-gradient(
            50% 50% at 50% 50%,
            rgba(var(--color-circle-shadow-2-rgb), 0.4) 0,
            rgba(var(--color-circle-shadow-3-rgb), 0) 100%
        );
    transform: translate(60%, 60%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;

    .rtl & {
        transform: translate(-40%, 60%) scaleX(-1);
    }

    &--dark {
        @include rwd(tablet) {
            width: rem(540px);
            height: rem(540px);
        }

        transform: translate(15%, 25%);
        bottom: 0;
        right: 0;
        background: radial-gradient(
                circle at 10% -10%,
                transparent 50%,
                var(--color-text-dark) 0
            ),
            radial-gradient(
                50% 50% at 50% 50%,
                rgba(129, 129, 129, 40%) 33.33%,
                rgba(129, 129, 129, 0%) 100%
            );

        .rtl & {
            left: 0;
            right: auto;
            transform: translate(35%, 25%) scaleX(-1);

            @include rwdmax(tablet) {
                transform: translate(-25%, 25%) scaleX(-1);
            }
        }
    }

    &--light {
        @include rwd(tablet) {
            width: rem(540px);
            height: rem(540px);
        }

        transform: translate(15%, 15%);
        top: 0;
        right: 0;
        opacity: 0.4;
        background: radial-gradient(
                circle at 10% -10%,
                transparent 50%,
                var(--color-bg-primary) 0
            ),
            radial-gradient(
                50% 50% at 50% 50%,
                rgba(129, 129, 129, 53%) 33.33%,
                rgba(129, 129, 129, 0%) 100%
            );

        .rtl & {
            left: 0;
            right: auto;
            transform: translate(0%, 15%) scaleX(-1);

            @include rwdmax(tablet) {
                transform: translate(-25%, 15%) scaleX(-1);
            }
        }
    }
}
