.video {
  &__iframe-wrapper {
    padding: 56.25% 0 0 0;
    position: relative;
    height: 100%;
    width: 100%;

    &--smaller {
      padding: 42.19% 0 0 0;
    }
  }
}
