.loader {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;

    span {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        background-color: var(--color-dark-primary);
        margin: 35px 5px;
        opacity: 0;
    }

    span:nth-child(1) {
        animation: opacitychange 1s ease-in-out infinite;
    }

    span:nth-child(2) {
        animation: opacitychange 1s ease-in-out 0.33s infinite;
    }

    span:nth-child(3) {
        animation: opacitychange 1s ease-in-out 0.66s infinite;
    }
}

@keyframes opacitychange {
    0%,
    100% {
        opacity: 0;
    }

    60% {
        opacity: 1;
    }
}
