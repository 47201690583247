.home {
    background-image: url('../../img/home-bg.webp');
    background-size: cover;
    width: 100%;

    svg {
        height: rem(21px);
        width: rem(115px);
        fill: var(--color-text-dark);
    }
}
