.popup {
    background-color: var(--color-bg-body);
    border-radius: rem(25px);
    overflow: hidden;
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    width: 100%;
    max-height: 90%;
    padding: 0;

    &-wrapper {
        background: rgba(33, 31, 32, 70%);
        backdrop-filter: blur(12.5px);
        position: fixed;
        width: 100%;
        height: 100vh;
        z-index: 99;
        top: 0;
        left: 0;
    }

    &-close {
        position: absolute;
        right: 2rem;
        top: 2rem;
        cursor: pointer;
        z-index: 1;
        color: var(--color-text-light);

        svg {
            width: 2rem;
            height: 2rem;
        }
    }
}
