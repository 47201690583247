.page-products {

    .breadcrumbs a,
    .breadcrumbs span {
        color: $color-text-light;
    }

    .breadcrumbs a {
        &:hover {
            color: $color-text-medium-light;
        }
    }

    .breadcrumbs {
        &>li:not(:first-child)::before {
            content: url('../../icons/arrow-breadcrumbs__white.svg?inline');
            margin-right: 0.25rem;
            display: inline-block;

            .rtl & {
                transform: rotate(180deg);
            }
        }
    }

    .link {
        cursor: pointer;
    }

    .section--listing-hero {
        background-image: url('../../img/listing--bg.webp');
        background-size: cover;
        max-height: 480px;
        width: 100%;

        h1 {
            color: $color-text-light;
            font-size: 36px;

            @include rwd(small) {
                font-size: 72px;
            }
        }
    }

    .section.listing {
        position: static;
    }

    .section--3to1ratio {
        @include rwd(tablet) {
            aspect-ratio: 5 / 1;
        }
    }

    .grid-heading {
        grid-template-columns: repeat(1, 1fr);
        display: grid;

        @include rwdmax(tablet) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
