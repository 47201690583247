.select {
  width: 100%;
  box-shadow: 0px 6px 19px 0px rgba(211, 211, 211, 0.08), 0px 19px 65px 0px rgba(211, 211, 211, 0.12), 0px 84px 289px 0px rgba(211, 211, 211, 0.18), 0px 0px 1px 0px rgba(142, 142, 142, 0.25);
  border: 1px solid rgba($color-text-medium, 0.3);
  border-radius: 6px;
  padding: .5rem 1rem;
  color: $color-text-medium;
  background: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.29289 7.36516C5.68342 6.97463 6.31658 6.97463 6.7071 7.36516L9.99999 10.6581L13.2929 7.36516C13.6834 6.97463 14.3166 6.97463 14.7071 7.36516C15.0976 7.75568 15.0976 8.38885 14.7071 8.77937L10.7071 12.7794C10.3166 13.1699 9.68341 13.1699 9.29289 12.7794L5.29289 8.77937C4.90237 8.38885 4.90237 7.75568 5.29289 7.36516Z' fill='%23737575'/%3E%3C/svg%3E%0A") no-repeat var(--color-text-light);
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}
