/*
    html
    body
*/

* {
    box-sizing: border-box;
}

html {
    direction: ltr;
    font-size: 16px;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 1.3;
    margin: 0;
    padding: 0;

    @include rwd(full) {
        font-size: 1.33em;
    }

    @include rwd('4k') {
        font-size: 2.33em;
    }

    @include rwd('8k') {
        font-size: 5.32em;
    }

    &.rtl {
        direction: rtl;
    }
}

body {
    background: var(--color-bg-body);
    font-size: 1em;
    font-family: var(--neueFrutigerWorld-font), sans-serif !important;
    font-weight: $font-weight-normal;
    color: var(--color-text-base);
    min-height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;

    &.js-language-popup-open {
        overflow: hidden;
    }
}
