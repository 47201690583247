.listing {
    &__header {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;
        grid-row-gap: 0;
        margin-bottom: $spacer-small;

        @include rwdmax(tablet) {
            grid-row-gap: 1rem;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__meta {
        grid-area: 1 / 4 / 1 / -1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include rwdmax(tablet) {
            grid-area: 2/1/2/-1;
        }
    }

    &__loader {
        background: #fff;
        opacity: .4;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &-header {
        margin-bottom: rem(16px) !important;
        margin-top: rem(16px) !important;
    }
}













///////

.h-full {
    height: 100%;
}

.listing {
    img {
        min-width: 1.5rem;
        max-width: 100%;
        object-fit: contain;
        aspect-ratio: 16/9;
    }

    &__empty {
        grid-column: 1/-1;
        background: $color-bg-primary;
        color: $color-text-medium;
        border-radius: 12px;

        span {
            color: $color-text-dark;
            margin-left: .25rem;
        }

        div {
            display: flex;
            align-items: center;
        }
    }

    &-heading--wrapper {
        display: flex;
        justify-content: space-between;
    }

    &-header {
        font-weight: 500;
        font-size: rem(28px);
        line-height: 140%;
    }

    &--hero-header {
        &__h1 {
            align-self: end;
            margin-bottom: 110px;
        }
    }

    &-products,
    &-listing-products {
        &--options {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
        }

        &--hide,
        &--show,
        &--reset {
            font-weight: 400;
            font-size: rem(16px);
            line-height: 145%;
        }
    }

    &-pagination {
        &--container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: rem(12px);
            margin-top: 40px;
            color: $color-text-medium;
            font-weight: 400;
            font-size: 1rem;
            line-height: 145%;

            span {
                display: inline-block;
                margin: 0 .15rem;
            }
        }

        &--back,
        &--next {
            margin: 0 !important;
            content: '';
            background-color: $color-text-medium;
            mask: url('../../icons/arrow-breadcrumbs.svg?inline') no-repeat 50% 50%;
            mask-size: cover;
            width: rem(14px);
            height: rem(14px);
            min-width: rem(14px);
            min-height: rem(14px);
            transition: $link-transition;
        }

        &--back {
            transform: rotateY(180deg);
        }
    }
}

.listing__grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
    grid-row-gap: 0;

    &.grid-container {
        padding: 0;
    }
}

a:hover~.listing__grid {
    display: grid;
}



.listing__grid .sidebar {
    grid-area: 1 / 1 / 2 / 4;
    position: fixed;
    width: 100vw;
    background: $color-bg-body;
    box-sizing: border-box;
    height: 100vh;
    list-style-type: none;
    transform-origin: 0 0;
    z-index: 2;
    left: 0;
    top: var(--hako-bar-height);
    overflow: auto;
    padding-top: 2.5rem;

    @include rwd(tablet) {
        position: inherit;
        width: initial;
        height: initial;
        background: transparent;
        padding-top: 0;
    }

    @include rwdmax(tablet) {
        padding-bottom: 1.75rem;

        .hako & {
            padding-bottom: 3.5rem;
        }
    }

    &:not(.filters--open) {
        display: none;
    }
}

.listing-products {
    grid-area: 1 / 1 / 1 / 13;

    .filters--open+& {
        @include rwd(tablet) {
            grid-area: 1 / 4 / 2 / 13;
        }
    }
}

.listing-products--container {
    position: relative;
    grid-template-columns: repeat(1, 1fr);
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @include rwd(small) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include rwd(tablet) {
        grid-template-columns: repeat(4, 1fr);
    }

    .filters--open+.listing-products & {
        @include rwd(tablet) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
