.status {
    @extend .box__highlight;
    color: var(--color-text-medium);

    &::before {
        content: '•';
        color: var(--color-status-green);
        margin-right: 0.25rem;
    }

    &--out {
        &::before {
            color: var(--color-status-red);
        }
    }
}
