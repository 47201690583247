/*
BACKGROUND COLORS

for example
.u-bg-primary
.u-txt-primary
 */

@use 'sass:meta';

@mixin syntax-colors($args...) {
    @each $name, $color in meta.keywords($args) {
        .u-txt-#{$name} {
            color: #{$color};
        }

        .u-bg-#{$name} {
            background-color: #{$color};
        }
    }
}

@include syntax-colors(
    $primary: var(--color-primary),
    $light-primary: var(--color-light-primary),
    $secondary: var(--color-bg-section),
    $medium: var(--color-text-medium)
);
