.mobile-menu {
    @include rwd(tablet) {
        display: none;
    }

    box-sizing: border-box;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: var(--color-bg-primary);
    list-style-type: none;
    -webkit-font-smoothing: antialiased;

    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    z-index: 9;

    &.mobile-menu--active {
        transform: none;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        border-bottom: 1px solid rgba(var(--color-text-medium-rgb), 0.3);

        &:last-child {
            border-bottom-color: var(--color-divide-dark);
        }
    }

    a {
        padding: 1rem;
        display: block;
    }
}

.mobile-product-bar {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: var(--color-light-primary);
    border-bottom: 1px solid var(--color-text-medium);
    width: 100%;

    @include rwd(tablet) {
        display: none;
    }
}
