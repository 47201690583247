.filters {
  &__options {
    grid-area: 1 / 1 / 1 / 4;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include rwdmax(tablet) {
      grid-area: 1/1/1/-1;
    }
  }
}

.filter {
  &-group {
    margin-bottom: $spacer-half;
    padding: 0 25px;

    @include rwd(tablet) {
      padding: 0;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      font-size: rem(20px);
      line-height: 145%;
      margin: 0 0 16px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: rem(20px);
    line-height: 145%;
    margin: 0 0 16px;

    &--mobile {
      filter: drop-shadow(0 12px 10px rgba($color-header-shadow, 0.04));
      background: $color-bg-body;
      display: flex;
      justify-content: space-between;
      padding: 0 25px 12px;
      margin-bottom: 12px;
      align-items: center;

      .hako & {
        padding: 50px 25px 12px;
      }

      div:first-child {
        display: flex;
        align-items: center;
        gap: 2rem;
      }

      &__filter {
        font-weight: 500;
        font-size: 20px;
        line-height: 145%;
      }

      &__reset {
        font-weight: 400;
        font-size: 16px;
        line-height: 145%;
      }

      &__close {
        margin: 8px -4px 8px 0;

        div {
          margin: 0 !important;
          content: '';
          background-color: $color-text-medium;
          mask: url('../../icons/close.svg?inline') no-repeat 50% 50%;
          mask-size: cover;
          width: rem(32px);
          height: rem(32px);
          min-width: rem(32px);
          min-height: rem(32px);
          transition: $link-transition;
        }
      }

      @include rwd(tablet) {
        display: none;
      }
    }
  }

  &-close {
    width: 20px;
    height: 20px;
    display: block;
    position: relative;

    &::before,
    &::after {
      position: absolute;
      right: 9px;
      top: 5px;
      content: ' ';
      height: 20px;
      width: 2px;
      background-color: $color-text-dark;
    }

    &::before {
      transform: rotate(90deg);
    }

    &::after {
      transform: rotate(90deg);
    }

    &.filter-close--open {
      &::after {
        transform: rotate(0deg);
      }
    }
  }
}
