.section {
    position: relative;
    padding: rem(72px) 0;
    z-index: 0;
    overflow: hidden;
    background-color: var(--color-bg-body);

    &--gray {
        background-color: var(--color-bg-primary);
    }

    &--triangle {
        background-color: var(--color-bg-primary);
        background-image: url('../../img/grey-bg.webp');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    &--dark {
        background-color: var(--color-bg-section);
        color: var(--color-text-light);
    }

    &--black {
        background-color: var(--color-text-dark);
    }

    &--image {
        background-image: url('../../img/section.webp');
        background-size: cover;
        background-position: center;
        width: 100%;
        aspect-ratio: 3/1;

        @include rwd(desktop) {
            aspect-ratio: 21 / 9;
        }
    }

    &--animation {
        img.animate {
            @include rwd(desktop) {
                animation: scaling 3s alternate;
            }
        }
    }

    &--video {
        background-color: var(--color-text-dark);
        width: 100%;
        overflow: visible;
        position: relative;
        padding: 0;
    }

    &--floating {
        overflow: visible;
        z-index: 1;
        padding-top: 0;

        @include rwd(desktop) {
            padding-top: 5.5rem;
            padding-bottom: 5.5rem;
        }

        .grid-container {
            position: relative;
        }

        img {
            @include rwd(desktop) {
                position: absolute;
                top: -35%;
                left: 0;
                width: fit-content;
                max-width: 619px !important;
            }

            @include rwd(full) {
                top: -20%;
            }

            @include rwdmax(desktop) {
                max-width: 380px !important;
            }

            .rtl & {
                @include rwd(desktop) {
                    left: auto;
                    right: 0;
                }
            }
        }
    }

    &--pim {
        .paragraph--big {
            @include rwdmax(tablet) {
                line-height: rem(30px);
            }
        }
    }
}

@keyframes scaling {
    from {
        transform: scale(3);
    }

    to {
        transform: scale(1);
    }
}
