:root {
    --number-of-columns: 4;
}

.comparison {

    &-products-2 {
        --number-of-columns: 3;
    }

    &-bar {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        background: $color-bg-primary;
        z-index: 1;
        padding: 1rem 1rem;

        @include rwd(tablet) {
            padding: 0.8rem 2rem 0.6rem 2rem;
            z-index: 10;
        }

        img {
            max-height: 1.5em;
        }

        @include rwd(tablet) {
            &__wrapper {
                display: flex;
                justify-content: space-between;

            }
        }

        &__heading {
            display: flex;
            justify-content: space-between;

            @include rwdmax(tablet) {
                margin-bottom: 0.3em;
            }
        }

        &__items {

            &-wrapper {
                font-size: $font-small;

                @include rwd(tablet) {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    font-weight: $font-weight-medium;

                }
            }

            @include rwdmax(tablet) {
                margin-left: -0.3em;
                margin-right: -0.3em;

                .swiper-wrapper {
                    display: flex;
                    justify-content: center;

                    .comparison-item {
                        width: 33%;
                        position: relative;

                        .comparison-bar__close {
                            position: absolute;
                            right: 0.25rem;
                            top: -0.25rem
                        }

                        .box-label {
                            background: transparent;
                            text-align: center;
                        }
                    }
                }
            }

            @include rwd(tablet) {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                font-weight: $font-weight-medium;

                .swiper {
                    margin-left: 1rem
                }
            }

            .box-label {
                background-color: $color-bg-body;
                display: flex;
                flex-direction: column;
                padding: 0.4em 0.5em;
                gap: 0.25em;

                &__label {
                    color: $color-text-dark;
                }

                @include rwd(tablet) {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                }
            }
        }

        &__action {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            font-size: $font-small;

            @include rwdmax(tablet) {
                flex-wrap: wrap;
                justify-content: space-around;
                margin-top: 0.8em;
            }

            &-warning {
                display: flex;
                align-items: center;
                gap: rem(5px);
                font-size: $font-small;

                @include rwdmax(tablet) {
                    font-weight: $font-weight-medium;
                }

                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        &__close {
            svg {
                width: 1em;
                height: 1em;
            }

        }

        &__reset {
            display: flex;
            align-items: center;
            margin-left: 0.6em;
            gap: 0.25em;

            svg {
                width: 1.2em;
                height: 1.2em;
            }
        }

        .swiper-slide {
            width: auto;
        }

        .swiper-android .swiper-slide {
            @include rwdmax(tablet) {
                width: auto !important;
            }
        }

        .swiper-ios .swiper-slide {
            @include rwdmax(tablet) {
                width: min-content !important;
            }
        }
    }

    &-overlay {
        position: fixed;
        left: 0;
        bottom: 0;
        height: 100vh;
        background: $color-bg-body;
        width: 100%;
        z-index: 11;
        font-size: $font-small;

        @include rwdmax(tablet) {
            body:not(.hako) & {
                top: 0;
                bottom: auto;
            }
        }

        @include rwdmax(small) {
            .grid-container {
                width: 100%;
            }
        }

        body.hako & {
            height: calc(93vh - var(--hako-bar-development));

            @include rwdmax(tablet) {
                height: calc(88vh - var(--hako-bar-development));
            }
        }

        .u-bg-light-primary div {
            background-color: inherit;
        }

        &__header {
            display: flex;
            font-size: $font-small;
            justify-content: space-between;
            padding: rem(15px) 1rem;

            @include rwd(tablet) {
                padding: rem(25px) rem(20px) rem(25px) rem(65px);
            }
        }

        &__btn {
            display: flex;
            align-items: center;
            gap: rem(10px);

            &.close svg {
                width: rem(20px);
                height: rem(20px);
            }
        }

        &__products-container {
            display: grid;
            grid-template-columns: repeat(var(--number-of-columns), 1fr);
            grid-template-rows: 1fr;
            grid-row-gap: 0;

            @include rwdmax(tablet) {
                margin-bottom: rem(25px);
            }
        }

        &__products-wrapper {
            grid-column: 2/-1;
            display: grid;
            grid-template-columns: repeat( calc( var(--number-of-columns) - 1 ), 1fr);
            gap: rem(20px);

            @include rwdmax(tablet) {
                grid-column: 1/-1;
            }

            .btn {
                max-width: 100%;
                line-height: 1.2;
            }

            p {
                display: block;
                overflow-wrap: anywhere;
                @include rwdmax(tablet) {
                    font-size: 0.85rem;
                }
            }

            & > div:not(.comparison-overlay__product) {
                display: grid;
                grid-template-rows: 1fr;
            }
        }

        &__product {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 1rem rem(90px) 2rem minmax(60px, 1fr) auto;

            @include rwdmax(tablet) {
                row-gap: 0.25rem;
                grid-template-rows: 1rem rem(70px) 2rem minmax(60px, 1fr) auto;
            }

            & > * {
                grid-column: 1/-1;
            }

            &-close {
                @include rwd(tablet) {
                    grid-column: -2/-1;
                }
                @include rwdmax(tablet) {
                    text-align: right;
                }

                svg {
                    width: rem(20px);
                    height: rem(20px);
                }

                &-footer {
                    display: flex;
                    align-items: center;
                    gap: rem(4px);

                    svg {
                        width: rem(20px);
                        height: rem(20px);
                    }
                }
            }

            &-desc {
                @include rwd(tablet) {
                    width: 90%;
                }
            }

            img {
                max-height: rem(80px);
                width: auto;
                margin: 0 auto;

                @include rwdmax(tablet) {
                    height: rem(60px);
                }
            }

            p {
                font-size: $font-small;
                font-weight: $font-weight-medium;
            }

            button {
                width: fit-content;
                max-width: 100%;
                line-height: 1.2;
            }

        }
    }

    &-overlay__body {
        overflow-y: scroll;
        margin-top: rem(10px);
        height: 55%;
        width: 100%;
        overflow-x: hidden;

        @include rwd(tablet) {
            margin-top: 0;
            height: 66%;
        }

        &-wrapper {
            margin-bottom: 20px;
        }
    }

    &-table {
        margin-top: rem(5px);
        margin-bottom: rem(30px);

        @include rwdmax(tablet) {
            margin-top: rem(20px);
        }

        &__row {
            display: grid;
            grid-template-columns: repeat(var(--number-of-columns), 1fr);
            grid-template-rows: 1fr;
            grid-row-gap: 0;
            padding: rem(9px) 0;
            column-gap: rem(20px);
            flex-wrap: wrap;

            @include rwdmax(tablet) {
                grid-template-columns: repeat( calc( var(--number-of-columns) - 1 ), 1fr);
                padding-top: rem(11px);
                padding-bottom: rem(12px);
            }
        }

        &__cell {
            overflow-wrap: anywhere;

            &:first-of-type {
                @include rwdmax(tablet) {
                    width: 100%;
                    border-bottom: 1px solid $color-bg-body;
                    padding-bottom: .5rem;
                    grid-column: 1/-1;
                    margin-bottom: .5rem;
                }
            }

            &:not(:first-of-type) {
                @include rwdmax(tablet) {
                    padding-top: rem(2px);
                }
            }
        }

        .u-bg-primary {
            .comparison-table__cell {
                &:first-of-type {
                    @include rwdmax(tablet) {
                        border-bottom: 1px solid $color-bg-primary;
                    }
                }
            }
        }
    }
}
