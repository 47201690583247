$font-weights: ('normal', 'bold');

@each $font-weight in $font-weights {
    .u-font-#{$font-weight} {
        font-weight: #{$font-weight};
    }
}

.u-font-semibold {
    font-weight: 500;
}
