.languages {
    background-color: var(--color-bg-body);
    border-radius: rem(25px);
    position: absolute;
    width: 100vw;
    max-height: 80vh;
    height: 80vh;
    -webkit-overflow-scrolling: touch;
    overflow: hidden;

    @include rwd(desktop) {
        transform: translateY(-50%) translateX(-50%);
        top: 50%;
        left: 50%;
    }

    @include rwdmax(desktop) {
        max-height: 100%;
        height: 100%;
        border-radius: 0;
        padding: 0;
        top: 0;
        left: 0;
        right: 0;
        grid-column: auto;
    }

    & > .row {
        grid-gap: 0;
        height: inherit;
        grid-template-rows: min-content;
        overflow: auto;
    }

    &-wrapper {
        background: rgba(33, 31, 32, 70%);
        backdrop-filter: blur(12.5px);
        position: fixed;
        width: 100%;
        height: 100vh;
        z-index: 99;
        top: 0;
        left: 0;
        right: 0;
    }

    &-close {
        position: absolute;
        right: 1rem;
        top: 1.5rem;
        cursor: pointer;
        z-index: 1;
        color: var(--color-text-medium);

        @include rwd(tablet) {
            right: 2rem;
            top: 2rem;
        }

        .rtl & {
            right: auto;
            left: 1rem;

            @include rwd(tablet) {
                left: 2rem;
            }
        }

        svg {
            width: 2rem;
            height: 2rem;
        }
    }

    &__header {
        @include rwd(desktop) {
            padding: rem(48px) rem(64px) rem(24px);
            border-radius: 1.5625rem 1.5625rem 0 0;
        }

        padding: 1.5rem 1rem;
        background-color: var(--color-bg-primary);
        background-image: url('../../img/grey-bg.webp');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 1;
        max-width: 100vw;
        box-sizing: border-box;
        position: fixed;
        width: 100%;

        .logo {
            padding: 0;

            svg {
                width: rem(146px);
                height: rem(41px);
            }
        }
        &--hidden{
            opacity: 0;
            position: static;
        }
    }

    &__body {
        padding: rem(32px) rem(64px) rem(48px);
        column-count: 4;
        position: relative;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;

        @include rwdmax(desktop) {
            padding: rem(20px) 1rem;
            column-count: 3;
        }

        @include rwdmax(tablet) {
            column-count: 2;
            padding-bottom: 3rem;
        }

        @include rwdmax(small) {
            column-count: auto;
            padding-bottom: 5rem;
        }

        @supports (-webkit-touch-callout: none) {
            padding-bottom: 8rem;
        }
    }

    .paragraph--big {
        margin-top: 2rem;
        break-after: avoid;
        margin-bottom: 1rem;

        &:first-child {
            margin-top: 0;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li a {
        display: inline-block;
        color: var(--color-text-medium);
        padding: 4px 0;
        cursor: pointer;

        &:hover {
            color: var(--color-text-dark);
        }
    }
}
