.u-display-none {
    display: none;
}

@include rwdmax(tablet) {
    .hide-on-mobile {
        display: none;
    }

    .div2.header-div2 {
        grid-area: initial;
    }

    .grid-container.grid.mobile-flex-remove-grid {
        display: flex;
        padding: initial;
    }
}

@include rwd(tablet) {
    .hide-on-desktop {
        display: none;
    }
}

.flex {
    display: flex;
}
