$color-text-light: #fff;
$color-text-medium-light: #cecece;
$color-text-dark: #211f20;
$color-text-medium: #737575;
$color-text-medium-lighter: #868888; // different than in figma because of Accessibility
$color-text-medium-darker: #686A6A; // different than in figma because of Accessibility
$color-text-medium-rgb: 115, 117, 117;
$color-text-base: $color-text-dark;
$color-link: $color-text-base;
$color-bg-body: #fff;
$color-bg-primary: #eeecea;
$color-light-primary: #eeecea;
$color-dark-primary: #b893e2;
$color-button-primary-light: #ead7ff;
$color-button-primary-dark: #b893e2;
$color-button-secondary-dark: #4a2b67;
$color-button-primary-light-hover: #e0c3ff;
$color-button-primary-dark-hover: #c9ade9;
$color-button-secondary-dark-hover: #643b8a;
$color-bg-section: #1d0035;
$color-divide: #e2ddd8;
$color-divide-dark: #818182;
$color-status-green: #5cf18f;
$color-status-red: #f96e6e;
$color-button-tertiary-hover: #3d383a;
$color-shadow: #d99de1;
$color-shadow-rgb: 217, 157, 225;
$color-shadow-2: #ce91f3;
$color-shadow-2-rgb: 206, 145, 243;
$color-ellipsis-shadow-1: #864aca;
$color-ellipsis-shadow-1-rgb: 134, 74, 202;
$color-ellipsis-shadow-2: #9d6cd5;
$color-ellipsis-shadow-2-rgb: 157, 108, 213;
$color-ellipsis-shadow-3: $color-button-primary-dark;
$color-ellipsis-shadow-3-rgb: 184, 147, 226;
$color-circle-shadow-1: $color-bg-section;
$color-circle-shadow-1-rgb: 29, 0, 53;
$color-circle-shadow-2: #c08fe8;
$color-circle-shadow-2-rgb: 192, 143, 232;
$color-circle-shadow-3: #c090e8;
$color-circle-shadow-3-rgb: 192, 144, 232;
$color-header-shadow: #a1a1a1;
$color-header-shadow-rgb: 161, 161, 161;

:root,
.theme-purple {
    --color-text-light: #{$color-text-light};
    --color-text-dark: #{$color-text-dark};
    --color-text-medium: #{$color-text-medium};
    --color-text-medium-lighter: #{$color-text-medium-lighter};
    --color-text-medium-darker: #{$color-text-medium-darker};
    --color-text-base: #{$color-text-base};
    --color-link: #{$color-link};
    --color-bg-body: #{$color-bg-body};
    --color-bg-primary: #{$color-bg-primary};
    --color-light-primary: #{$color-light-primary};
    --color-dark-primary: #{$color-dark-primary};
    --color-button-primary-text: #{$color-text-base};
    --color-button-primary-text-dark: #{$color-text-base};
    --color-button-secondary-text: #{$color-text-light};
    --color-button-tertiary-text: #{$color-light-primary};
    --color-button-primary-light: #{$color-button-primary-light};
    --color-button-primary-dark: #{$color-button-primary-dark};
    --color-button-secondary-dark: #{$color-button-secondary-dark};
    --color-button-primary-light-hover: #{$color-button-primary-light-hover};
    --color-button-primary-dark-hover: #{$color-button-primary-dark-hover};
    --color-button-secondary-dark-hover: #{$color-button-secondary-dark-hover};
    --color-bg-section: #{$color-bg-section};
    --color-divide: #{$color-divide};
    --color-divide-dark: #{$color-divide-dark};
    --color-status-green: #{$color-status-green};
    --color-status-red: #{$color-status-red};
    --color-button-tertiary-hover: #{$color-button-tertiary-hover};
    --color-shadow: #{$color-shadow};
    --color-shadow-2: #{$color-shadow-2};
    --color-ellipsis-shadow-1: #{$color-ellipsis-shadow-1};
    --color-ellipsis-shadow-2: #{$color-ellipsis-shadow-2};
    --color-ellipsis-shadow-3: #{$color-ellipsis-shadow-3};
    --color-circle-shadow-1: #{$color-circle-shadow-1};
    --color-circle-shadow-2: #{$color-circle-shadow-2};
    --color-circle-shadow-3: #{$color-circle-shadow-3};
    --color-header-shadow: #{$color-header-shadow};
    --color-text-medium-rgb: #{$color-text-medium-rgb};
    --color-shadow-rgb: #{$color-shadow-rgb};
    --color-shadow-2-rgb: #{$color-shadow-2-rgb};
    --color-ellipsis-shadow-1-rgb: #{$color-ellipsis-shadow-1-rgb};
    --color-ellipsis-shadow-2-rgb: #{$color-ellipsis-shadow-2-rgb};
    --color-ellipsis-shadow-3-rgb: #{$color-ellipsis-shadow-3-rgb};
    --color-circle-shadow-1-rgb: #{$color-circle-shadow-1-rgb};
    --color-circle-shadow-2-rgb: #{$color-circle-shadow-2-rgb};
    --color-circle-shadow-3-rgb: #{$color-circle-shadow-3-rgb};
    --color-header-shadow-rgb: #{$color-header-shadow-rgb};
}

.theme-blue {
    --color-dark-primary: #569fd8;
    --color-button-primary-text: #{$color-text-dark};
    --color-button-primary-text-dark: #{$color-text-light};
    --color-button-secondary-text: #{$color-text-light};
    --color-button-primary-light: #a5d3f6;
    --color-button-primary-dark: #1f5a89;
    --color-button-secondary-dark: #1f5a89;
    --color-button-primary-light-hover: #7ebae8;
    --color-button-primary-dark-hover: #467eab;
    --color-button-secondary-dark-hover: #467eab;
    --color-bg-section: #002039;
    --color-shadow-2: #0d3871;
    --color-circle-shadow-1: #002039;
    --color-circle-shadow-2: #569fd8;
    --color-circle-shadow-3: #569fd8;
    --color-ellipsis-shadow-1: #569fd8;
    --color-shadow-2-rgb: 13, 56, 113;
    --color-circle-shadow-1-rgb: 0, 32, 57;
    --color-circle-shadow-2-rgb: 86, 159, 216;
    --color-circle-shadow-3-rgb: 86, 159, 216;
    --color-ellipsis-shadow-2-rgb: 86, 159, 216;
    --color-ellipsis-shadow-3-rgb: 86, 159, 216;
}

.theme-green {
    --color-dark-primary: #5bd2b6;
    --color-button-primary-text: #{$color-text-dark};
    --color-button-primary-text-dark: #{$color-text-light};
    --color-button-secondary-text: #{$color-text-light};
    --color-button-primary-light: #9aeeda;
    --color-button-primary-dark: #26826D;
    --color-button-secondary-dark: #26826D;
    --color-button-primary-light-hover: #63d6bb;
    --color-button-primary-dark-hover: #47b59b;
    --color-button-secondary-dark-hover: #47b59b;
    --color-bg-section: #00231b;
    --color-shadow-2: #00322c;
    --color-circle-shadow-1: #00231b;
    --color-circle-shadow-2: #00b490;
    --color-circle-shadow-3: #00b490;
    --color-ellipsis-shadow-1: #5bd2b6;
    --color-shadow-2-rgb: 0, 50, 44;
    --color-circle-shadow-1-rgb: 0, 35, 27;
    --color-circle-shadow-2-rgb: 0, 180, 144;
    --color-circle-shadow-3-rgb: 0, 180, 144;
    --color-ellipsis-shadow-2-rgb: 91, 210, 182;
    --color-ellipsis-shadow-3-rgb: 91, 210, 182;
}
