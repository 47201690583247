/*
p, dfn, cite, em, i, pre, code
 */

p,
.paragraph,
%paragraph {
    // Neue Frutiger
    font-size: 1rem;
    font-weight: $font-weight-normal;
    line-height: 1.45;

    @include rwdmax(tablet) {
        margin-block-start: 0.5rem;
    }

    &--small {
        font-size: rem(14px);
        line-height: 1.35;
    }

    &--big {
        font-size: rem(20px);
    }

    &--medium {
        font-weight: $font-weight-medium;
    }
}
