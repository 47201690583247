.socials {
    display: flex;
    gap: 1rem;
    align-items: center;
    align-self: start;
    width: 100%;
    padding-top: 2rem;

    @include rwd(desktop) {
        padding-top: 0;
        justify-content: end;
    }

    a {
        display: block;

        svg {
            height: rem(32px);
            max-width: rem(32px);
            transition: $link-transition;
            fill: var(--color-text-medium);
        }

        &:hover {
            svg {
                fill: var(--color-text-dark);
            }
        }
    }
}
