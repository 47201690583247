.slide-up-enter {
    transform: translateY(100%);
}

.slide-up-enter-active {
    transform: translateY(0);
    transition: transform 300ms;
}

.slide-up-exit {
    transform: translateY(0);
}

.slide-up-exit-active {
    transform: translateY(100%);
    transition: transform 300ms;
}
