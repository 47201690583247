@mixin container() {
    box-sizing: border-box;
    padding-left: $spacer-xxsmall;
    padding-right: $spacer-xxsmall;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;

    @include rwd(desktop) {
        padding-left: $spacer-xxlarge;
        padding-right: $spacer-xxlarge;
        max-width: $content-width;
    }
}

.container,
%container {
    @include container;

    &--boxed {
        @include rwd(desktop) {
            max-width: 980px;
        }
    }

    &--wide {
        @include rwd(desktop) {
            max-width: $content-width-wide;
        }
    }

    &--full {
        @include rwd(desktop) {
            max-width: $content-width-full;
        }

        padding-left: 0;
        padding-right: 0;
    }
}

.row,
%row {
    display: grid;
    grid-column-gap: $spacer-xsmall;
    row-gap: 0;
    column-gap: $spacer-xsmall;

    @extend %column-gap;
}

@for $i from 1 through ($columns + 1) {
    @include rwd(desktop) {
        .columns-#{$i},
        %columns-#{$i} {
            grid-template-columns: repeat($i, 1fr);
        }
    }

    @include rwdmax(desktop) {
        .columns-tablet-#{$i},
        %columns-tablet-#{$i} {
            grid-template-columns: repeat($i, 1fr);
        }
    }

    @include rwdmax(tablet) {
        .columns-mobile-#{$i},
        %columns-mobile-#{$i} {
            grid-template-columns: repeat($i, 1fr) !important;
        }
    }
}

@for $i from -1 through ($columns + 1) {
    .grid-column-start-#{$i} {
        @include rwd(desktop) {
            grid-column-start: $i;
        }
    }

    .grid-column-end-#{$i} {
        @include rwd(desktop) {
            grid-column-end: $i;
        }
    }

    .grid-column-tablet-start-#{$i} {
        @include rwd(tablet) {
            grid-column-start: $i;
        }
    }

    .grid-column-tablet-end-#{$i} {
        @include rwd(tablet) {
            grid-column-end: $i;
        }
    }

    .grid-column-mobile-start-#{$i} {
        @include rwdmax(tablet) {
            grid-column-start: $i !important;
        }
    }

    .grid-column-mobile-end-#{$i} {
        @include rwdmax(tablet) {
            grid-column-end: $i !important;
        }
    }
}

.grid-container,
%grid-container {
    grid-column: 1/-1;
    grid-auto-flow: column;

    @include rwd(desktop) {
        grid-column: 3 / -3;
    }

    @include rwdmax(desktop) {
        padding-left: $spacer-xxsmall;
        padding-right: $spacer-xxsmall;
    }
}

.grid,
%grid {
    @extend %row;
    @extend %columns-mobile-4;
    @extend %columns-tablet-8;
    @extend %columns-12;

    &--boxed {
        @include rwd(small) {
            grid-template-columns: 1fr 3fr 1fr;
        }

        @include rwd(desktop) {
            grid-template-columns: 5fr 8fr 5fr;
        }

        .grid-container {
            @include rwd(small) {
                grid-column: 2 / -2;
            }
        }
    }

    &:not(.grid-container) > div:not(.grid-container) {
        @include rwdmax(desktop) {
            padding-left: $spacer-xxsmall;
            padding-right: $spacer-xxsmall;
        }
    }

    &-center--8col {
        grid-area: 1 / 1 / 2 / 5;

        @include rwd(tablet) {
            grid-area: 1 / 2 / 2 / 8;
        }
        @include rwd(desktop) {
            grid-area: 1 / 3 / 2 / 11;
        }
    }
}

@media only screen and (max-width: 600px) {
    .languages.grid-container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
